const API={
    insterBooth:"/booth/insterBooth",//添加展位分配方案
    list:"/booth/list",//方案列表
    delList:"/booth/delList",//方案历史列表
    editBooth:"/booth/editBooth",//编辑方案post
    insterBoothRegion:"/booth/insterBoothRegion",//添加区域post
    regionList:"/booth/regionList",//分区列表get
    editBoothRegion:"/booth/editBoothRegion",//编辑分区的名称post
    insterBoothSeat:"/booth/insterBoothSeat",//添加位置post
    editSeat:"/booth/editSeat",//编辑位置post
    boothRegionSeat:"/booth/boothRegionSeat",//通过分区id查找位置列表get
    deleteSeat:"/booth/deleteSeat",//删除位置get
    delBoothRegion:"/booth/delBoothRegion",//删除区域get
    updateBooth:"/booth/updateBooth",//更新方案状态get
    delBooth:"/booth/delBooth",//删除方案get

    insertJob:"/jobFair/insertJob",//添加招聘会post
    jobFairlist:"/jobFair/list",//招聘会列表post
    jobFairdelList:"/jobFair/delList",//招聘会历史列表post
    boothList:"/booth/boothList",//方案列表没有分页get
    selectBooth:"/jobFair/selectBooth",//选择方案get
    postStatus:"/jobFair/postStatus",//更改发布状态get
    getdetail:"/jobFair/detail",//编辑时返回详情get
    editJob:"/jobFair/editJob",//编辑招聘会post
    detailInfo:"/jobFair/detailInfo",//查看招聘会详情get
    delJob:"/jobFair/delJob",//删除招聘会get
    locationReview:"/booth/locationReview",//位置审核get
    location:"/booth/location",//位置锁定get
    queryMemberByCompanyName:"/member/queryMemberByCompanyName",//企业关键词搜索get
    selectWorkByMemberIdNotPage:"/work/selectWorkByMemberIdNotPage",//根据企业id获取职位信息get
    school:"/school",//学校管理
    registrationIntention:"/registrationIntention",//学校管理
    registraTotal:"/registraTotal",//学校管理
    enterprise:"/offline/enterprise",//线下企业
    jobWanted:"/offline/jobWanted",//线下求职者
    getSubstation:"/homepage/getSubstation",//各分站企业数统计
    exportRegistration:"/registrationIntention/exportRegistration",//导出意向信息
    labor:"/labor",//劳动人民
    laborlist:"/labor/list",//劳动人民
    laborgetTargetList:"/labor/getTargetList",//人员分类
}

export default API
