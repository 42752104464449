<template>
    <div>
        <div class="wz">
            <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>招聘会</el-breadcrumb-item>
            <el-breadcrumb-item>编辑招聘会</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="box">
            <div class="add">
                <el-form ref="form" :rules="rules" :model="form" label-width="120px">
                    <el-form-item label="招聘会名称" prop="jobName">
                        <el-input v-model="form.jobName" style="width:400px;"></el-input>
                    </el-form-item>
                    <el-form-item label="主办方" prop="organizer">
                        <el-input v-model="form.organizer" style="width:400px;"></el-input>
                    </el-form-item>
                    <el-form-item label="举办时间">
                        <el-date-picker
                        v-model="value1"
                        type="datetimerange"
                        range-separator="至"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                        </el-date-picker>
                    </el-form-item>
                     <el-form-item label="地区" prop="holdField" v-show="userInfo.orgId==0">
                        <el-select v-model="form.orgId" placeholder="请选招聘会地区">
                            <el-option
                            v-for="item in orglist"
                            :key="item.orgId"
                            :label="item.districtName"
                            :value="item.orgId">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="举办会场" prop="holdField">
                        <el-input v-model="form.holdField" style="width:400px;"></el-input>
                    </el-form-item>
                    <el-form-item label="公交线路">
                        <el-input v-model="form.trafficRoutes" style="width:400px;"></el-input>
                    </el-form-item>
                    <el-form-item label="回看视频">
                        <el-input v-model="form.videoUrl" style="width:400px;"></el-input>
                    </el-form-item>
                    <el-form-item label="是否网络招聘会">
                        <el-radio v-model="form.network" :label="0">否</el-radio>
                        <el-radio v-model="form.network" :label="1">是</el-radio>
                    </el-form-item>
                    <el-form-item label="负责人" prop="contacts">
                        <el-input v-model="form.contacts" style="width:200px;"></el-input>
                    </el-form-item>
                    <el-form-item label="负责人电话" prop="contactsPhone">
                        <el-input v-model="form.contactsPhone"  style="width:200px;"></el-input>
                    </el-form-item>
                    <el-form-item label="宣传图">
                        <upload-img :modeldata="form" @changeurl="getimgurl" style="margin-top:15px;"></upload-img>
                    </el-form-item>
                    <el-form-item label="招聘会介绍">
                         <el-input type="textarea" v-model="form.jobFairIntroduce" :rows="7"></el-input>
                    </el-form-item>
                    <el-form-item label="媒体宣传">
                         <el-input type="textarea" v-model="form.mediaPublicity" :rows="7"></el-input>
                    </el-form-item>
                    <el-form-item label="超值服务套餐">
                         <el-input type="textarea" v-model="form.jobExplain" :rows="7"></el-input>
                    </el-form-item>
                    <el-form-item label="展位设置方案">
                         <el-input type="textarea" v-model="form.boothSettingScheme" :rows="7"></el-input>
                    </el-form-item>
                    <el-form-item label="参与办法">
                         <el-input type="textarea" v-model="form.participatoryApproach" :rows="7"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit('form')">确定编辑</el-button>
                        <el-button>取消</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
import apiurl from '@/api/booth'
import _api from '@/api/index'
export default {
    data(){
        return{
            userInfo:JSON.parse(window.localStorage.getItem("userInfo")),
            orglist:JSON.parse(localStorage.getItem("org")),
            value1:[],//举办时间
            form:{
                id:"",//要编辑的id
                jobName:"",
                organizer:"",//主办方
                holdField:"",//会场
                trafficRoutes:"",//公交线路
                videoUrl:"",//公交线路
                contacts:"",//负责人
                contactsPhone:"",//联系电话
                url:"",//宣传图片
                jobFairIntroduce:"",//介绍
                mediaPublicity:"",//媒体宣传
                jobExplain:"",//超值服务套餐
                boothSettingScheme:"",//展位设置方案
                participatoryApproach:"",//参与办法
                holdStartTime:"",//开始时间
                holdEndTime:"",//结束时间
                network:0,//结束时间
                orgId:null,
            },
            rules:{
                jobName:[
                    { required: true, message: '请输入招聘会名称', trigger: 'blur' },
                ],
                organizer:[
                    { required: true, message: '请输入主办方名称', trigger: 'blur' },
                ],
                holdField:[
                    { required: true, message: '请输入举办会场', trigger: 'blur' },
                ],
                contacts:[
                    { required: true, message: '请输入负责人', trigger: 'blur' },
                ],
                contactsPhone:[
                    { required: true, message: '请输入联系电话', trigger: 'blur' },
                ]
            }
        }
    },
    created(){
        this.form.id=this.$route.query.id
        this.getDetail()
    },
    methods:{
        getDetail(){
            _api.get(apiurl.getdetail,{id:this.form.id}).then(res=>{
                console.log(res)
                if(res.success){
                    this.form.jobName=res.data.jobName
                    this.form.organizer=res.data.organizer
                    this.form.holdField=res.data.holdField
                    this.form.trafficRoutes=res.data.trafficRoutes
                    this.form.videoUrl=res.data.videoUrl
                    this.form.contacts=res.data.contacts
                    this.form.contactsPhone=res.data.contactsPhone
                    this.form.url=res.data.url
                    this.form.jobFairIntroduce=res.data.jobFairIntroduce
                    this.form.mediaPublicity=res.data.mediaPublicity
                    this.form.jobExplain=res.data.jobExplain
                    this.form.boothSettingScheme=res.data.boothSettingScheme
                    this.form.participatoryApproach=res.data.participatoryApproach
                    this.form.holdStartTime=res.data.holdStartTime
                    this.form.holdEndTime=res.data.holdEndTime
                    this.form.network=res.data.network
                    this.form.orgId=res.data.orgId

                    this.value1.push(res.data.holdStartTime)
                    this.value1.push(res.data.holdEndTime)
                    //this.value1[1]=res.data.holdEndTime

                }
            })
        },
        getimgurl(){

        },
        onSubmit(form){
            console.log()
            this.$refs[form].validate((valid)=>{
                if (valid) {
                    //this.form.jobFairIntroduce=this.form.jobFairIntroduce.replace(/\n/g, '<br />')
                    this.form.holdStartTime=this.value1[0]
                    this.form.holdEndTime=this.value1[1]
                    //alert('submit!');
                    _api.post(apiurl.editJob,this.form).then(res=>{
                        console.log(res)
                        if(res.success){
                            this.$message.success("编辑招聘会成功！")
                            //Object.keys(this.form).forEach(key=>(this.form[key]=""))
                            //this.value1=[]
                            this.$router.push("/jobfair/index")
                        }
                    })

                } else {
                    return false;
                }
            })
        }
    }
}
</script>
<style scoped>
.add{ width:900px;}
</style>
